import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Product, ProductGroep} from "../../../../models/Producten";

@Injectable()
export class ProductenService {


  constructor(private http: HttpClient) {

  }

  addProductGroep(productGroep: ProductGroep, inventaris) {
    const URI = '/api/productgroep';
    const headers = new HttpHeaders;
    productGroep = Object.assign(productGroep, {inventarisId: inventaris._id});
    headers.append('Content-Type', 'application/json');
    return this.http.post(URI, productGroep, {headers: headers});
  }

  getProductGroepen() {
    const URI = '/api/productgroepen';
    const headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.get(URI, {headers: headers});
  }

  addProduct(product: Product, productGroep: ProductGroep, inventarisJaar) {
    const URI = '/api/productgroep/product';
    const headers = new HttpHeaders;
    console.log('add product', product, productGroep, inventarisJaar)
    const body = {
      jaar: inventarisJaar,
      artikelNummer: product.artikelNummer,
      naam: product.naam,
      beginVoorraad: parseInt(product.productInfo.find(info => info.jaar === inventarisJaar).beginVoorraad.toString(), 0),
      aantal: product.productInfo.find(info => info.jaar === inventarisJaar).aantal,
      eindVoorraad: product.productInfo.find(info => info.jaar === inventarisJaar).eindVoorraad,
      btwPercentage: product.productInfo.find(info => info.jaar === inventarisJaar).btwPercentage,
      prijs: product.productInfo.find(info => info.jaar === inventarisJaar).prijs,
      productGroepId: productGroep._id,
      verkoopPrijs: product.productInfo.find(info => info.jaar === inventarisJaar).verkoopPrijs
    };
    headers.append('Content-Type', 'application/json');
    return this.http.post(URI, body, {headers: headers});
  }

  updateProduct(product: Product, productInfoId) {
    const URI = '/api/productgroep/product';
    const headers = new HttpHeaders;
    const body = {
      productInfoId: productInfoId,
      productId: product._id,
      artikelNummer: product.artikelNummer,
      naam: product.naam,
      beginVoorraad: parseInt(product.productInfo.find(info => info._id === productInfoId).beginVoorraad.toString(), 0),
      aantal: product.productInfo.find(info => info._id === productInfoId).aantal,
      eindVoorraad: product.productInfo.find(info => info._id === productInfoId).eindVoorraad,
      btwPercentage: product.productInfo.find(info => info._id === productInfoId).btwPercentage,
      prijs: product.productInfo.find(info => info._id === productInfoId).prijs,
      verkoopPrijs: product.productInfo.find(info => info._id === productInfoId).verkoopPrijs
    };
    headers.append('Content-Type', 'application/json');
    return this.http.put(URI, body, {headers: headers});
  }

  getInventaris(jaar) {
    const URI = '/api/inventaris/' + jaar;
    const headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.get(URI, {headers: headers});
  }

  createInventaris(jaar) {
    const URI = '/api/inventaris';
    const headers = new HttpHeaders;
    const body = {
      jaar: jaar
    };
    headers.append('Content-Type', 'application/json');
    return this.http.post(URI, body, {headers: headers});
  }

  deleteProduct(product) {
    const URI = '/api/product/' + product._id;

    return this.http.delete(URI);
  }

  deleteProductGroep(productGroep) {
    const URI = '/api/productgroep/' + productGroep._id;
    return this.http.delete(URI);
  }

  getVoorraadBewegingen(productInfoId) {
    const URI = '/api/voorraadbeweging/' + productInfoId;
    return this.http.get(URI);
  }

  copyInventaris(inventaris) {
    const URI = '/api/inventaris/copy';
    const body = {
      fromJaar: inventaris.jaar - 1,
      toJaar: inventaris.jaar
    }
    return this.http.post(URI, body);
  }

  addVoorraadBeweging(beweging, productInfoId) {
    const URI = '/api/voorraadbeweging/' + productInfoId;
    return this.http.post(URI, beweging);
  }

  updateProductInfo(productInfo) {
    const URI = '/api/productInfo/' + productInfo._id;
    return this.http.put(URI, productInfo)
  }
}
