import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit {

  paginationValue = 10;
  paginationMinValue = 1;
  paginationMaxValue = 1;

  pageNumberValue = 1;
  numberOfPages;
  pagesArray = [1];

  @Input() totalItems;

  @Input()
  get paginationMax() {
    return this.paginationMaxValue;
  }
  @Output() paginationMaxChange = new EventEmitter();
  set paginationMax(value) {
    this.paginationMaxValue = value;
    this.paginationMaxChange.emit(value);
  }


  @Input()
  get paginationMin() {
    return this.paginationMinValue;
  }
  @Output() paginationMinChange = new EventEmitter();
  set paginationMin(value) {
    this.paginationMinValue = value;
    this.paginationMinChange.emit(value);
  }


  @Input()
  get nrOfItems() {
    return this.paginationValue;
  }
  @Output() nrOfItemsChange = new EventEmitter();
  set nrOfItems(value) {
    localStorage.setItem('paginationItems', value.toString())
    this.setNumberOfItems(this.totalItems);
    this.paginationValue = value;
    this.nrOfItemsChange.emit(value);
    this.setPage(1);
  }

  constructor() {

  }

  ngOnInit(): void {

  }

  setNumberOfItems(totalItems) {
    console.log('number of items', totalItems)
    this.numberOfPages = Math.floor(((totalItems - 1) / this.nrOfItems) + 1);
    if(this.nrOfItems && totalItems) {
    this.pagesArray = [];
    for (let i = 0; i < this.numberOfPages && this.numberOfPages >= 2; i++) {
      const j = i + 1;
      this.pagesArray.push(j);
    }
    if (this.numberOfPages < 2) {
      this.pagesArray.push(1);
    }
  }
  }

  setPage(pageNumber) {
    this.pageNumberValue = pageNumber;
    this.paginationMin = (this.nrOfItems * (pageNumber - 1)) + 1;
    this.paginationMax = this.nrOfItems * pageNumber;
  }
}
