<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center mt-5">
                <h3>Wachtwoord opnieuw instellen</h3>
                <div class="mt-4">
                    <label for="newPassword" class="col-sm-3">Nieuw wachtwoord</label> 
                    <input type="password" name="newPassword" id="newPassword" [(ngModel)]="newPassword" class="form-control col-sm-4" style="display:inline-block;" required>
                </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 text-center mt-2">
                <label for="verifyPassword" class="col-sm-3">Bevestig wachtwoord</label> 
                <input type="password" name="verifyPassword" id="verifyPassword" [(ngModel)]="verifyPassword" class="form-control col-sm-4" style="display:inline-block;" required>
        </div>
    </div>
    <div class="row" *ngIf="newPassword !== verifyPassword">
        <div class="col-sm-12 text-center">
            <div class="col-sm-3" style="display:inline-block;"></div>
            <span style="color:red;">Wachtwoorden komen niet overeen</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 text-center mt-4">
                <div class="col-sm-3" style="display:inline-block;"></div>
                <button name="submit" id="resetPasswordClickEvt" class="btn btn-primary mt-2 col-sm-2" (click)="resetPasswordClicked($event)"
                [disabled]="(newPassword !== verifyPassword) || newPassword === '' || newPassword === undefined">Reset</button>
        </div>
    </div>
</div>
