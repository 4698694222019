<div class="container">
	<div class="row">
		<div class="col-sm-12 text-center">
			<div class="mt-5">
				<h3>Wachtwoord vergeten</h3>
				<div class="mt-4">
					<label for="username" class="col-sm-3">E-mail</label> 
					<input type="email" [(ngModel)]="email" name="username" id="username" class="form-control col-sm-4" style="display:inline-block;" required>
				</div>
			</div>
		</div>
		<div class="col-sm-12 text-center mt-4">
			<div class="col-sm-3" style="display:inline-block;"></div>
			<button type="submit" id="forgotPasswordClickEvt" (click)="forgotPasswordClicked($event)" class="btn btn-primary col-sm-3"  style="display:inline-block;">Reset wachtwoord</button>
		</div>
	</div>
</div>
