<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <a class="navbar-brand" href="#">Adminboek</a>
  <button class="navbar-toggler" (click)="isNavbarCollapsed = !isNavbarCollapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto" *ngIf="!currentUser">
        <li class="nav-item nav-link">Login om van start te gaan. Nog geen account? <span class="btn-link" [routerLink]="['register']">Registreer</span></li>
      </ul>
      <ul class="navbar-nav mr-auto" *ngIf="currentUser">
        <li class="nav-item"><a class="nav-link" [routerLink]="['/home']">Dashboard</a></li>

        <li ngbDropdown class="nav-item">
          <a ngbDropdownToggle class="nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Offertes</a>
          <div ngbDropdownMenu>
              <li ngbDropdownItem *ngIf="!expired"><a class="nav-link" [routerLink]="['/offertes/create']">Nieuwe Offerte</a></li>
              <li ngbDropdownItem><a class="nav-link" [routerLink]="['/offertes']">Overzicht</a></li>
          </div>
        </li>

        <li ngbDropdown class="nav-item">
          <a ngbDropdownToggle class="nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Facturen</a>
          <div ngbDropdownMenu>
              <li ngbDropdownItem *ngIf="!expired"><a class="nav-link" [routerLink]="['/facturen/create']">Nieuwe Factuur</a></li>
              <li ngbDropdownItem><a class="nav-link" [routerLink]="['/facturen']">Overzicht</a></li>
          </div>
        </li>

        <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/facturen']">Facturen</a></li> -->
        <li ngbDropdown class="nav-item">
        <a ngbDropdownToggle class="nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Boeken</a>
          <div ngbDropdownMenu>
            <li ngbDropdownItem><a class="nav-link" [routerLink]="['/verkopen']">Verkopen</a></li>
            <li ngbDropdownItem><a class="nav-link" [routerLink]="['/aankopen']">Aankopen</a></li>
            <li ngbDropdownItem><a class="nav-link" [routerLink]="['/financieel']">Financieel</a></li>
            <li ngbDropdownItem><a class="nav-link" [routerLink]="['/uitgaande-creditnotas']">Uitg. creditnota's</a></li>
            <li ngbDropdownItem><a class="nav-link" [routerLink]="['/ontvangen-creditnotas']">Ontv. creditnota's</a></li>
            <li ngbDropdownItem><a class="nav-link" [routerLink]="['/inventaris']">Inventaris</a></li>
          </div>
        </li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/klanten']">Klanten</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/leveranciers']">Leveranciers</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/btw']">Btw</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/klantenlisting']">Klantenlisting</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/ic-listing']">IC Listing</a></li>
        <li class="nav-item" *ngIf="currentUser" style="cursor:pointer;"><a class="nav-link">
          <a style="text-decoration:none; color:white;" [routerLink]="['/account']">
            <button class="btn btn-sm btn-secondary">
              <i class="fa fa-user"></i>
              {{currentUser.username}}
            </button>
          </a>
          <a [routerLink]="['/login']" (click)="logout()"><button class="btn btn-secondary btn-sm ml-2">Uitloggen</button></a>
        </a></li>
      </ul>
  </div>
</nav>
