import { CurrencyFormatPipe } from '../_pipes/currency-format.pipe';
import { PaginationComponent } from '../shared/pagination/pagination.component';
import { TableFilterMenuComponent } from '../shared/table-filter-menu/table-filter-menu.component';
import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { PaginationService } from '../_services/pagination/pagination.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import * as _ from 'lodash';
import { map, skip, switchMap, take, takeUntil, startWith } from 'rxjs/operators';
import { orderBy as _orderBy } from 'lodash-es';

@Component({
  selector: 'app-paginated',
  template: ''
})
export abstract class PaginatedComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(TableFilterMenuComponent) menuFilter: TableFilterMenuComponent;
  @ViewChild(PaginationComponent) pagination: PaginationComponent;

  currentNrOfPages;
  activePage;
  oldActivePage = null;
  currentUser = null;

  refresh$ = new BehaviorSubject(false);

  sortDatumAsc = false;
  sortKlantAsc = false;
  paginatedItemsExclBtw = 0;
  paginatedItemsInclBtw = 0;

  btwValue;

  numberOfItems = localStorage.getItem('paginationItems') ? parseInt(localStorage.getItem('paginationItems')) : 10;

  paginationMin = 1;
  paginationMax = 1;
  orderBy: string;
  private _unsubscribe = new Subject();

  abstract loadItems();
  abstract orderByReversed;

  paginatedItemsOriginal = [];
  paginatedItems = [];



  constructor(protected paginationService: PaginationService,
             @Inject('DEFAULT_ENTITY') protected entity: string,
             private currencyFormatPipe: CurrencyFormatPipe
             ) {
    this.entity = entity;
    if(this.paginationService.getActivePage(this.entity)) {
      this.oldActivePage = this.paginationService.getActivePage(this.entity);
    }
    this.paginationService.initPagination(entity);
    this.currentNrOfPages = this.paginationService.getNrOfItems(entity);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));


  }

  ngOnInit() {

  }

  setOrderBy(value, noRefresh?) {
    this.orderBy = value;
    this.orderByReversed[value] = this.orderByReversed[value] == 'asc' ? 'desc' : 'asc';
    this.paginatedItems = Object.assign([], _orderBy(this.paginatedItems, value, this.orderByReversed[value]));
    this.paginatedItemsOriginal = Object.assign([], _orderBy(this.paginatedItemsOriginal, value, this.orderByReversed[value]));
    //if(!noRefresh)
      //this.refresh$.next(true);
  }

  ngOnDestroy() {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }

  addIds(items) {
    items.forEach((value, index) => {
      items[index] = Object.assign(value, {id: (index + 1)});
    });
    this.paginatedItemsOriginal = items;
  }

  ngAfterViewInit() {

    // this.loadItems().pipe(
    //   take(1)
    // ).subscribe(items => {

    //   this.addIds(items);
    //   this.paginatedItemsOriginal = items;
    //   this.paginatedItems = items;

    //   this.setOrderBy('datum');
    // });

    // if(!this.menuFilter) {
    //   this.refresh$.pipe(takeUntil(this._unsubscribe)).subscribe(() => {
    //     this.paginatedItems = Object.assign([], this.paginatedItemsOriginal);
    //     this.pagination.setNumberOfItems(this.paginatedItems.length);
    //   })
    //   return ;
    // }

    let source$: any;

    if(!this.menuFilter) {
      source$ = this.refresh$;
    } else {
      source$ = combineLatest([
        this.refresh$,
        this.menuFilter.jaar$,
        this.menuFilter.kwartaal$,
        this.menuFilter.student$
      ])
    }

    source$
    .pipe(
      // skip(1),
      switchMap(() => this.loadItems()
                            .pipe(
                              map((loadedItems: any) => {
                                this.paginatedItemsOriginal = loadedItems;
                                this.paginatedItems = loadedItems;
                                this.addIds(this.paginatedItems);
                                return loadedItems;
                              }),
                              take(1))
                            ),
      takeUntil(this._unsubscribe)
      )
    .subscribe(triggers => {
      if (this.currentUser.role === 'Boekhouder' && !this.menuFilter.currentKlant) {
        this.paginatedItems = [];
      }

      if (this.menuFilter && this.menuFilter.currentKlant) {
        this.paginatedItems = <any[]> this.menuFilter.filterByKlant(this.paginatedItems);
      }
      if (this.menuFilter && this.menuFilter.currentStudent) {
        this.paginatedItems = <any[]> this.menuFilter.filterByStudent(this.paginatedItems, this.paginatedItemsOriginal);
      }

      if(this.menuFilter) {
        this.paginatedItems = this.menuFilter.changeYear(this.paginatedItems);
          if (this.menuFilter.filterByKwartaal) {
            this.paginatedItems = <any[]> this.menuFilter.changeKwartaal(this.paginatedItems);
          }
      }

      this.pagination.setNumberOfItems(this.paginatedItems.length);
    });
  }

  isLeerkracht() {
    return this.currentUser.role === 'Leerkracht';
  }

  sortBy(param) {
    if (param === 'datum') {
      this.sortDatumAsc ? this.paginatedItems = _.sortBy(this.paginatedItems, (item) => item.datum) :
      this.paginatedItems = _.reverse(_.sortBy(this.paginatedItems, (item) => item.datum));

      this.sortDatumAsc = !this.sortDatumAsc;
    }
    if (param === 'klant') {
      this.sortKlantAsc ? this.paginatedItems = _.sortBy(this.paginatedItems, (item) => item.klant.name) :
      this.paginatedItems = _.reverse(_.sortBy(this.paginatedItems, (item) => item.klant.name));

      this.sortKlantAsc = !this.sortKlantAsc;
    }
  }

  // get paginatedItems() {
  //   return  this.menuFilter && this.menuFilter.items$.getValue() ? this.menuFilter.items$.getValue() : [];
  // }

  // set paginatedItems(paginatedItems: any[]) {
  //   this.menuFilter.items$.next(paginatedItems);
  // }

  // get paginatedItemsOriginal() {
  //   return this.menuFilter.itemsOriginal;
  // }

  // set paginatedItemsOriginal(paginatedItems: any[]) {
  //   this.menuFilter.itemsOriginal = paginatedItems;
  // }

  paginatedItemsTotaal(rangeItems: any[]) {
    this.paginatedItemsExclBtw = 0;
    this.paginatedItemsInclBtw = 0;

    rangeItems.forEach(item => this.paginatedItemsExclBtw += item.bedragExclBTW);
    rangeItems.forEach(item => this.paginatedItemsInclBtw += item.bedragInclBTW);
  }

  totaalBtw() {
    let totaalBtw = 0;
    if (this.paginatedRange !== undefined) {
    this.paginatedRange.forEach(item => {
        totaalBtw = totaalBtw + (item.bedragInclBTW - item.bedragExclBTW);
     });
  }
    return totaalBtw;
  }

  get paginatedRange() {
    const rangeItems = [];
    this.paginatedItems.forEach((item, i) => {
      if ((i + 1) >= this.paginationMin && (i + 1) <= this.paginationMax) {
        rangeItems.push(item);
      }
    });
    this.paginatedItemsTotaal(rangeItems);
    return rangeItems;
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '#,';
    for (let index in headerList) {
     row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
     let line = (i+1)+'';
     for (let index in headerList) {
      let head = headerList[index];


      if(array[i][head] instanceof Object) {
        if(head === 'klant') line += ',' + array[i][head]['name'];
        if(head === 'leverancier') line += ',' + array[i][head]['name'];
        if(head === 'factuur') line += ',' + array[i][head]['factuurNummer'];
      } else {
        if(head === 'datum') {
          var today = new Date(array[i][head]);
          var year = today.getFullYear();
          var mes = today.getMonth()+1;
          var dia = today.getDate();
          var fecha =dia+"-"+mes+"-"+year;
          line += ',' + fecha;
        }
        else if(head === 'bedragInclBTW' || head === 'bedragExclBTW' || head === 'bedrag') line += ',' + this.currencyFormatPipe.transform(array[i][head])
        else line += ',' + array[i][head];
      }


     }
     str += line + '\r\n';
    }
    return str;
   }

   downloadFile(data, filename='data', paginatedComponent: string) {
     let csvData;
     if(paginatedComponent === 'verkopen') csvData = this.ConvertToCSV(data, ['datum', 'klant', 'bedragExclBTW', 'BTW', 'bedragInclBTW', 'omschrijving', 'factuur', 'status', 'kortingVoorContant', 'terugstuurbareVerpakking']);
     if(paginatedComponent === 'aankopen') csvData = this.ConvertToCSV(data, ['type', 'datum', 'leverancier', 'bedragExclBTW', 'BTW', 'bedragInclBTW', 'status', 'bijlage', 'omschrijving']);
     if(paginatedComponent === 'verrichtingen') csvData = this.ConvertToCSV(data, ['datum', 'type', 'bedrag', 'omschrijving']);
     if(paginatedComponent === 'uitgCreditNotas') csvData = this.ConvertToCSV(data, ['datum', 'klant', 'bedragExclBTW', 'BTW', 'bedragInclBTW', 'omschrijving']);
     if(paginatedComponent === 'ontvCreditNotas') csvData = this.ConvertToCSV(data, ['datum', 'klant', 'bedragExclBTW', 'BTW', 'bedragInclBTW', 'omschrijving']);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
