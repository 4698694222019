import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountStripeService {

  constructor(private http: HttpClient) { }

  pay() {
    let data = {
      name: 'Nicholas',
      email: 'lievens.nicholas@gmail.com',
      postal: '3191'
    };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Credentials', 'true')
      .set('Access-Control-Allow-Methods', 'POST')
      .set('Access-Control-Allow-Headers', 'Content-Type')
      .set('Authorization', '*');

    return this.http.post('/api/pay', JSON.stringify(data), {
      headers: headers,
    })
  }
}
