<c-container class="container">
  <c-row class="row">
    <c-col sm="12">
      <div class="alert alert-danger" *ngIf="expired">Uw account is verstreken. Ga naar de <a href="account">account</a> pagina om een betaling uit te voeren en het account opnieuw te activeren.</div>


      <c-card class="card mb-4">
        <c-card-header class="card-header">
    Jouw cijfers voor {{currYear}}
        </c-card-header>
        <c-card-body>
            <c-row>
              <c-col md="12" xl="12" xs="12">
                <c-row>
                  <c-col sm="4">
                    <div
                      class="border-start border-start-4 border-start-success py-1 px-3"
                    >
                      <div class="text-medium-emphasis small">Verkopen</div>
                      <div class="fs-5 fw-semibold">{{totaalVerkopen | currencyFormat}}</div>
                    </div>
                  </c-col>
                  <c-col sm="4">
                    <div
                      class="border-start border-start-4 border-start-danger py-1 px-3"
                    >
                      <div class="text-medium-emphasis small">
                        Aankopen
                      </div>
                      <div class="fs-5 fw-semibold">{{totaalAankopen | currencyFormat}}</div>
                    </div>
                  </c-col>
                  <c-col sm="4">
                    <div
                      class="border-start border-start-4 border-start-info py-1 px-3"
                    >
                      <div class="text-medium-emphasis small">
                        Resultaat
                      </div>
                      <div class="fs-5 fw-semibold">{{(totaalVerkopen - totaalAankopen) | currencyFormat}}</div>
                    </div>
                  </c-col>
                </c-row>
              </c-col>
            </c-row>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
<c-row class="mb-4">
  <c-col sm="6">
    <c-card>
      <c-card-header>
        Verkopen
      </c-card-header>
      <c-body>
        <div class="text-center m-2" style="height:300px;">
          <ngx-charts-bar-vertical
            [scheme]="colorScheme"
            [results]="somVerkopen"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="true"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [yAxisTickFormatting]="yToEuro"
          >
            <ng-template #tooltipTemplate let-model="model">
              {{model.value | currencyFormat}}
            </ng-template>
          </ngx-charts-bar-vertical>
        </div>
      </c-body>
    </c-card>
  </c-col>

  <c-col sm="6">
    <c-card>
      <c-card-header>
        Aankopen
      </c-card-header>
      <c-body>
        <div class="text-center m-2" style="height:300px;">
          <ngx-charts-bar-vertical
            [scheme]="colorScheme"
            [results]="somAankopen"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="true"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [yAxisTickFormatting]="yToEuro"
          >
            <ng-template #tooltipTemplate let-model="model">
              {{model.value | currencyFormat}}
            </ng-template>
          </ngx-charts-bar-vertical>
        </div>
      </c-body>
    </c-card>
  </c-col>
</c-row>

<div *ngIf="currentUser.role === 'Leerkracht'">
  <h1 class="mt-4">Studenten</h1>
  <p>
    U bent ingelogd als leerkracht en vindt hieronder een lijst terug van uw studenten. Om studenten aan uw account te linken
    dienen ze zich te registreren en gebruik te maken van de code die u terugvindt op uw gebruikerspagina. Klik op uw gebruikersnaam
    in de navigatie bovenaan om naar uw gebruikerspagina te gaan.
  </p>
  <div>
    <table class="table table-striped mt-4">
      <thead>
        <th>Voornaam</th>
        <th>Naam</th>
        <th>Acties</th>
      </thead>
      <tbody>
        <tr *ngFor="let student of currentUser.studenten">
        <td>{{student?.firstName}}</td>
        <td>{{student?.lastName}}</td>
        <td><i class="fa fa-remove" style="cursor:pointer;" ngbTooltip="Student verwijderen" (click)="removeStudent(student)"></i></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


</c-container>
