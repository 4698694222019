import { Router } from '@angular/router';
import { TableFilterMenuComponent } from './../shared/table-filter-menu/table-filter-menu.component';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { groupBy, map, mergeMap, skip, switchMap, take, toArray } from 'rxjs/operators';
import {VerkoopService} from "../shared/services/verkoop.service";
import {Verkoop} from "../../models/Verkoop";

@Component({
  selector: 'app-klantenlisting',
  templateUrl: './klantenlisting.component.html'
})
export class KlantenlistingComponent implements OnInit, AfterViewInit {

  @ViewChild(TableFilterMenuComponent) jaarFilter: TableFilterMenuComponent;

  klantenIds = [];

  // enabled;

  constructor(private verkopenService: VerkoopService, private router: Router) {
    // this.enabled = this.router.parseUrl(this.router.url).queryParams['extraFeatures']
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    console.log(currentUser);
    this.jaarFilter.jaar$
    .pipe(
      switchMap(() => this.verkopenService.getAllVerkopen(currentUser)))
    .subscribe((verkopen: Verkoop[]) => {
      this.klantenIds = [];
      let _verkopen = verkopen.filter(item => {
        const itemDatum: Date = new Date(item.datum);
        console.log(itemDatum.getFullYear(), parseInt(this.jaarFilter.jaar$.getValue().toString()))
        return itemDatum.getFullYear() === parseInt(this.jaarFilter.jaar$.getValue().toString());
      });

      console.log(_verkopen)

      _verkopen.forEach(verkoop => {
       if(this.klantenIds.findIndex((id: any) => id.klant._id === verkoop.klant._id) === -1 && verkoop.klant.btwNr.startsWith('BE')) {
         console.log('add klant')
         this.klantenIds.push({klant: verkoop.klant, verkopen: [], totaal: 0, btwTotaal: 0, list: false});
       }
      });

      this.klantenIds.forEach(id => {
        _verkopen.forEach(verkoop => {
          if (verkoop.klant._id === id.klant._id) {
            id.verkopen.push(verkoop)
          }
        })
      })

      this.klantenIds.forEach(id => {
        let klantTotaal = 0;
        let btwTotaal = 0;
        id.verkopen.forEach((_verkoop: Verkoop) => {
          klantTotaal += _verkoop.bedragExclBTW;
          btwTotaal += _verkoop.bedragInclBTW - _verkoop.bedragExclBTW;
        });
        if(klantTotaal > 250) { id.list = true; id.totaal = klantTotaal; id.btwTotaal = btwTotaal };
      })
    });
  }

  get totaalOmzet() {
    let totaal = 0;
    this.klantenIds.forEach(klantenId => {
      if(klantenId.list) {
        klantenId.verkopen.forEach(verkoop => {
            totaal += verkoop.bedragExclBTW;
        });
      }
    });
    return totaal;
  }

  get totaalBTW() {
    let totaal = 0;
    this.klantenIds.forEach(klantenId => {
      if (klantenId.list) {
        klantenId.verkopen.forEach(verkoop => {
            totaal += verkoop.bedragInclBTW - verkoop.bedragExclBTW;
        });
      }
    });
    return totaal;
  }

}
