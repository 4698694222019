import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Factuur } from '../../../models/Factuur';
import { map } from 'rxjs/operators';

@Injectable()
export class FacturenService {

  constructor(private http: HttpClient) {
  }

  addFactuur(factuur: Factuur, registreerVerkoop: boolean){
    console.log('registreer', registreerVerkoop)
    let URI = '/api/facturen/';
    let headers = new HttpHeaders;
    let body = {
      klantNaam: factuur.klantNaam,
      klantAdres1: factuur.klantAdres1,
      klantAdres2: factuur.klantAdres2,
      btwNrKlant: factuur.btwNrKlant,

      bedrijfsNaam: factuur.bedrijfsNaam,
      bedrijfsAdres1: factuur.bedrijfsAdres1,
      bedrijfsAdres2: factuur.bedrijfsAdres2,

      btwNr: factuur.btwNr,
      IBAN: factuur.IBAN,
      BIC: factuur.BIC,
      telefoon: factuur.telefoon,

      factuurNummer: factuur.factuurNummer,
      datum: factuur.datum,
      teBetalenVoor: factuur.teBetalenVoor,
      artikels: factuur.artikels,
      btwPercentage: factuur.btwPercentage,
      rpr: factuur.rpr,
      leveringsDatum: factuur.leveringsDatum,
      handelskorting: factuur.handelskorting,

      kosten: factuur.kosten,
      contanteKorting: factuur.contanteKorting,
      terugstuurbareVerpakking: factuur.terugstuurbareVerpakking,

      opmerking: factuur.opmerking,
      btwPerArtikel: factuur.btwPerArtikel,
      registreerVerkoop: registreerVerkoop,
      autoGenerated: factuur.autoGenerated,
      user: factuur.user
    };
    headers.append('Content-Type', 'application/json');
    return this.http.post(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

  getAllFacturen(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/facturen/' + currentUser._id, {headers:headers})
    .pipe(map(res => res));
  }

  removeFactuur(factuurId: string) {
    let URI = `/api/facturen/${factuurId}`;
    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.delete(URI, {headers})
    .pipe(map(res => res));
  }

  getAllStudentenFacturen(currentUser){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/facturen/studenten/' + currentUser.leerkrachtCode, {headers: headers})
    .pipe(map(res => res));
  }

  getKlantenFacturen(currentUser){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/facturen/klanten/' + currentUser.boekhouderCode, {headers: headers})
    .pipe(map(res => res));
  }

  getFactuur(factuurId: string){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    return this.http.get('/api/facturen/factuur/' + factuurId, {headers: headers})
    .pipe(map(res => res));
  }

  updateFactuur(factuur: Factuur){
    let URI = `/api/facturen/factuur/${factuur._id}`;
    let headers = new HttpHeaders;
    let body = {
      klantNaam: factuur.klantNaam,
      klantAdres1: factuur.klantAdres1,
      klantAdres2: factuur.klantAdres2,
      btwNrKlant: factuur.btwNrKlant,

      bedrijfsNaam: factuur.bedrijfsNaam,
      bedrijfsAdres1: factuur.bedrijfsAdres1,
      bedrijfsAdres2: factuur.bedrijfsAdres2,

      btwNr: factuur.btwNr,
      IBAN: factuur.IBAN,
      BIC: factuur.BIC,
      telefoon: factuur.telefoon,

      factuurNummer: factuur.factuurNummer,
      datum: factuur.datum,
      teBetalenVoor: factuur.teBetalenVoor,
      artikels: factuur.artikels,
      btwPercentage: factuur.btwPercentage,
      rpr: factuur.rpr,
      leveringsDatum: factuur.leveringsDatum,
      handelskorting: factuur.handelskorting,

      kosten: factuur.kosten,
      contanteKorting: factuur.contanteKorting,
      terugstuurbareVerpakking: factuur.terugstuurbareVerpakking,

      opmerking: factuur.opmerking,
      btwPerArtikel: factuur.btwPerArtikel,
      autoGenerated: factuur.autoGenerated
    };
    headers.append('Content-Type', 'application/json');
    return this.http.put(URI, body, {headers: headers})
    .pipe(map(res => res));
  }
}
