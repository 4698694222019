<!--sidebar-->
<c-sidebar
  *ngIf="isLoggedIn"
  #sidebar="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar"
  visible
>
  <c-sidebar-brand
    [brandFull]="{
      src: 'assets/pictures/adminboek_logo.jpg',
      width: 82,
      height: 82,
      alt: 'Adminboek Logo'
    }"
    [brandNarrow]="{
      src: 'assets/pictures/adminboek_logo.jpg',
      width: 46,
      height: 46,
      alt: 'Adminboek Logo'
    }"
    routerLink="./"
  />

  <ng-scrollbar pointerEventsMethod="scrollbar">
    <c-sidebar-nav
      [navItems]="navItems"
      dropdownMode="close"
    />
  </ng-scrollbar>
  <c-sidebar-toggler
    *ngIf="!sidebar.narrow"
    toggle="unfoldable"
    cSidebarToggle="sidebar"
  />
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar" *ngIf="isLoggedIn" />
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer />
</div>

<!--<div class="wrapper">-->
<!--<app-header></app-header>-->
<!--<div class="container-fluid">-->
<!--<alert></alert>-->
<!--</div>-->
<!--<router-outlet></router-outlet>-->
<!--</div>-->
<!--<footer class="footer">-->
<!--<div class="container">-->
<!--  <div class="row">-->
<!--    <div class="col-12">-->
<!--      <div class="col-12 col-md-6 col-xl-5 d-inline-block text-muted">&copy; Applicatie ontwikkeld door <a href="https://www.facebook.com/lievensdev" target="_blank">Lievens.dev</a></div>-->
<!--      <div class="col-12 col-md-6 col-xl-2 d-inline-block"> <a target="_blank" href="https://www.facebook.com/Youiyoux-Accounting-1641526412630400/"><i class="fa fa-facebook-official"></i> Facebook-pagina</a></div>-->
<!--      <div class="col-12 col-md-12 col-xl-5 d-inline-block">Technische ondersteuning: <a href="mailto:lievens.nicholas@gmail.com">lievens.nicholas@gmail.com</a></div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--</footer>-->
<!--<ng-http-loader-->
<!--[debounceDelay]="100"-->
<!--[spinner]="spinkit.skThreeBounce">-->
<!--</ng-http-loader>-->
