import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AlertService } from '../_services/alert.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  email;

  constructor(protected http:HttpClient, protected alertService: AlertService) { }

  ngOnInit() {
  }

  forgotPasswordClicked(event) {
    let body = {username: this.email};
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    headers.append('Content-Type', 'application/json');
    this.http.post('/users/forgot_password', body, {headers: headers}).toPromise().then(result => {
      console.log(result)
      if(result) {
        this.alertService.success('Bekijk je e-mail om de reset link te gebruiken.')
      }
    },
    error => {
        this.alertService.error('Er is iets misgelopen bij het resetten van je wachtwoord. Onjuist e-mailadres?')
    })
  }
}
