import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { KlantService } from '../klant.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Klant } from '../../../models/Klant';
import { GeneralComponent } from '../../general/general.component'
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { I18n, CustomDatepickerI18n } from "../../ngb-date-fr-parser-formatter/ngb-i18n"
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "../../ngb-date-fr-parser-formatter/ngb-date-fr-parser-formatter"
import { debounceTime, switchMap, take } from 'rxjs/operators';
import { FormControl, NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-klant-edit',
  templateUrl: './klant-edit.component.html',
  providers: [
    I18n,
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ]
})
export class KlantEditComponent extends GeneralComponent implements OnInit {
  @ViewChild('klantForm', { static: true }) ngForm: NgForm;

  klant;
  isEdit = false;

  constructor(protected klantService: KlantService,
              private location: Location,
              private route: ActivatedRoute) {
                super();
               }

  ngOnInit() {
    let param = this.route.snapshot.params['id'];
    if(param){
      this.isEdit = true;
      this.klantService.getKlant(param)
      .pipe(take(1))
      .subscribe(klant =>
        {
          this.klant = klant
          this.klant.klantSubscr = this.convertInputDate(this.klant.klantSubscr);
        });
    }
    // new klant
    this.klant = {
      type: '',
      name: '',
      btwNr: '',
      contactpersoon: '',
      email: '',
      klantSubscr: null,
      adresStraat: '',
      adresGemeente: '',
      postcode: '',
      land: '',
      iban: '',
      bic: '',
      rpr: ''
    }
  }

  navigateAway(){
    this.location.back();
  }



  public onSubmit() {
    this.klant.klantSubscr = this.convertOutputDate(this.klant.klantSubscr);

    const addOrUpdateKlant = this.isNew(this.klant) ?
    this.klantService.addKlant(this.klant) :
    this.klantService.updateKlant(this.klant);

    addOrUpdateKlant
    .pipe(take(1))
    .subscribe(
      response => {
        this.location.back();
      },
    );
  }

}
