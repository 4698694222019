import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService } from '../_services/alert.service';
import { UserService } from '../_services/user.service';

import { v4 as uuid } from 'uuid';
import { take } from 'rxjs/operators';

@Component({
    templateUrl: 'register.component.html'
})

export class RegisterComponent {
    model: any = {};
    loading = false;
    agreed = false;
    passwordConfirm = null;

    allowBoekhouder = false;
    leerkrachtValid = false;
    leerkrachtValidated = false;
    leerkracht = null;

    constructor(
        private router: Router,
        private userService: UserService,
        private alertService: AlertService) {
          this.allowBoekhouder = this.router.parseUrl(this.router.url).queryParams['extraFeatures']
        }

    userTypeChange() {
        this.leerkrachtValid = false;
        this.leerkracht = null;
        this.leerkrachtValidated = false;
        console.log(this.leerkrachtValidated)
        if (this.model.role === 'Student') {
            this.model.leerkrachtCode = null;
        }
        if (this.model.role === 'Leerkracht'){
            this.model.leerkrachtCode = uuid();
            this.model.studenten = [];
        }
        if (this.model.role === 'Boekhouder') {
          this.model.boekhouderCode = 'b-' + uuid();
          this.model.klanten = [];
        }
    }

    register() {
        this.loading = true;
        this.userService.create(this.model)
            .pipe(take(1))
            .subscribe(
                data => {
                    this.alertService.success('Uw account is succesvol geregistreerd. U kan nu inloggen!', true);
                    this.router.navigate(['/login']);
                },
                error => {
                    this.alertService.error(error.message);
                    this.loading = false;
                });
    }

    isPasswordEqual(){
        return this.model.password === this.passwordConfirm;
    }

    validateLeerkracht(id) {
        return this.userService.checkLeerkracht(id).pipe(take(1)).subscribe(leerkracht => {
          this.leerkrachtValidated = true;
          if(leerkracht) {
            this.leerkrachtValid = true;
            this.leerkracht = leerkracht;
          } else {
            this.leerkrachtValid = false;
          }
        });
    }
}
