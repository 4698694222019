import {Pipe} from '@angular/core';

@Pipe({
    name: 'separator'
})
export class SeparatorPipe {
    transform(value: number): string {

        let returnString = null;
        if(value){
            returnString = value.toString().replace('.', ',');
        }

        if(returnString !== null){
            return returnString;
        } else {
            return value.toString();
        }
    }
}