import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[appBTW]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: BtwValidatorDirective, multi: true}]
})
export class BtwValidatorDirective implements AsyncValidator {

  constructor(private http: HttpClient) {

  }

  validate(control: AbstractControl): Observable<ValidationErrors|null> {
    if(control.value && control.value.length) {
      return timer(500).pipe(
        switchMap(() => this.validateBTW(control.value))
      )
    } else {
      return of(null);
    }
  }

  validateBTW(btwNr) {
    const sr =
        {btwNr: btwNr};

    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.post('/api/klant/btw-check', sr, {headers: headers})
    .pipe(
      map((res: any) => !res.isValid ? {'btw': false} : null));
  }
}
