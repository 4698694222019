import { CurrencyFormatPipe } from '../_pipes/currency-format.pipe';
import { SeparatorPipe } from '../_pipes/separator.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFilterMenuComponent } from './table-filter-menu/table-filter-menu.component';
import { PaginationComponent } from './pagination/pagination.component';
import { StudentBedrijfSelectorComponent } from './student-bedrijf-selector/student-bedrijf-selector.component';
import {DefaultHeaderComponent} from "./default-header/default-header.component";
import {
    AvatarComponent,
    BadgeComponent,
    BreadcrumbComponent,
    BreadcrumbRouterComponent,
    ButtonDirective,
    ContainerComponent,
    DropdownComponent,
    DropdownDividerDirective,
    DropdownHeaderDirective,
    DropdownItemDirective,
    DropdownMenuDirective,
    DropdownToggleDirective,
    FormCheckComponent,
    FormCheckInputDirective,
    FormCheckLabelDirective,
    FormLabelDirective,
    FormSelectDirective,
    HeaderDividerComponent,
    HeaderNavComponent,
    HeaderTogglerDirective,
    NavItemComponent,
    NavLinkDirective,
    SidebarToggleDirective,
    TextColorDirective
} from "@coreui/angular";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {IconDirective} from "@coreui/icons-angular";
import {DefaultFooterComponent} from "./default-footer/default-footer.component";

const PIPES = [
  SeparatorPipe,
  CurrencyFormatPipe
]

@NgModule({
  declarations: [
    TableFilterMenuComponent,
    PaginationComponent,
    ...PIPES,
    StudentBedrijfSelectorComponent,
    DefaultHeaderComponent,
    DefaultFooterComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        BreadcrumbComponent,
        ContainerComponent,
        HeaderNavComponent,
        NavItemComponent,
        HeaderDividerComponent,
        BreadcrumbRouterComponent,
        SidebarToggleDirective,
        HeaderTogglerDirective,
        NavLinkDirective,
        RouterLink,
        RouterLinkActive,
        IconDirective,
        ButtonDirective,
        DropdownToggleDirective,
        DropdownComponent,
        AvatarComponent,
        TextColorDirective,
        DropdownMenuDirective,
        DropdownHeaderDirective,
        DropdownItemDirective,
        BadgeComponent,
        DropdownDividerDirective,
        FormSelectDirective,
        FormCheckInputDirective,
        FormCheckComponent,
        FormCheckLabelDirective,
        FormLabelDirective
    ],
  exports: [
    TableFilterMenuComponent,
    PaginationComponent,
    StudentBedrijfSelectorComponent,
    DefaultHeaderComponent,
    BreadcrumbComponent,
    FormsModule,
    NgbModule,
    ...PIPES,
    DefaultFooterComponent
  ],
  providers: [
    ...PIPES
  ]
})
export class SharedModule { }
