import { Injectable } from '@angular/core';

import { User } from '../../models/user';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get('/users').pipe(map((response) => response));
    }

    getById(_id: string) {
        return this.http.get('/users/' + _id).pipe(map((response) => response));
    }

    create(user: User) {
        return this.http.post('/users/register', user);
    }

    update(user: User) {
        return this.http.put('/users/' + user._id, user);
    }

    delete(_id: string) {
        return this.http.delete('/users/' + _id);
    }

    checkLeerkracht(id) {
        return this.http.post('/users/validate_leerkracht', {id: id})
    }
}
