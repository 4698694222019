import { AuthenticationService } from './../_services/authentication.service';
import { Component, Directive, OnInit } from '@angular/core';


@Directive()
export class GeneralComponent implements OnInit {


  constructor() {}

  ngOnInit() {
  }

  convertInputDate(date: any): any{
    let convertedDate = new Date(date);
    if(date !== null) {
    let dateObject = {year: convertedDate.getFullYear(),
    day: convertedDate.getDate(),
    month: (convertedDate.getMonth() + 1)}
    return dateObject
    }
    else {
      return null;
    }
  }

  convertOutputDate(item:any){
    let date;
    if(item === null || (item && item.day && item.day.toString() === 'NaN')) {
      return date = null;
    } else {
      date = item.year + '-' + item.month + '-' + item.day;
      return date;
    }
  }

  calcBTW(btwValue, itemExclBtw, itemInclBtw, maatstafVanHeffing?) {
    let bedragExclBtw = Object.assign(itemExclBtw);
    bedragExclBtw = bedragExclBtw.toString().replace(',','.');
    if(typeof(btwValue) === 'number'){
      btwValue = "" + btwValue;
    }

    let calculatedBTW;

    switch (btwValue) {
      case "6": calculatedBTW = (((maatstafVanHeffing ? maatstafVanHeffing : bedragExclBtw) / 100) * 6); break;
      case "12": calculatedBTW = (((maatstafVanHeffing ? maatstafVanHeffing : bedragExclBtw) / 100) * 12); break;
      case "21": calculatedBTW = (((maatstafVanHeffing ? maatstafVanHeffing : bedragExclBtw) / 100) * 21); break;
      case "0": calculatedBTW = 0; break;
      case "-1" : calculatedBTW = 0; break;
    }

    if(calculatedBTW || calculatedBTW === 0) {
      itemInclBtw = parseFloat(bedragExclBtw.toString()) + parseFloat(calculatedBTW.toString());
    }

    let result = [itemExclBtw, calculatedBTW, itemInclBtw]

    return result;
  }

  isNew(item) {
    return !item._id || item._id === '' || item._id === null;
  }

  finalizeEdit(entity) {
    if (entity.BTW && entity.BTW === -1) {
      entity.BTW = 0;
    }
    entity.datum = this.convertOutputDate(entity.datum);
    if (entity.bedragExclBTW) {
      entity.bedragExclBTW = entity.bedragExclBTW.toString().replace(',', '.');
    }
  }

}
