import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import {  Observable, of, timer } from 'rxjs';
import {  map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[appBIC]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: BICValidatorDirective, multi: true}]
})
export class BICValidatorDirective implements AsyncValidator {

  constructor(private http: HttpClient) {

  }

  validate(control: AbstractControl): Observable<ValidationErrors|null> {
    if(control.value && control.value.length) {
      return timer(500).pipe(
        switchMap(() => this.validateBIC(control.value))
      )
    } else {
      return of(null);
    }
  }

  validateBIC(bic) {
    const sr =
        {bic: bic};

    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.post('/api/klant/bic-check', sr, {headers: headers})
    .pipe(
      map((res: any) => !res ? {'bic': false} : null));
  }
}
