import {Component, DoCheck, OnChanges, SimpleChanges} from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import {Angulartics2GoogleAnalytics} from "angulartics2";
import {INavData} from "@coreui/angular";
import {IconSetService} from "@coreui/icons-angular";
import {iconSubset} from "./icons/icon-subset";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements DoCheck {
  public spinkit = Spinkit;
  navItems: INavData[] = [];
  isLoggedIn = false;

  ngDoCheck() {
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      this.isLoggedIn = true;
    }
    if (!localStorage.getItem('currentUser')) {
      // logged in so return true
      this.isLoggedIn = false;
    }
  }

  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
              private iconSetService: IconSetService) {


    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
    angulartics2GoogleAnalytics.startTracking();

    this.navItems = [
      {
        name: 'Dashboard',
        url: '/dashboard',
        iconComponent: { name: 'cil-speedometer' }
      },
      {
        name: 'Offertes',
        url: '/offertes',
        iconComponent: { name: 'cil-inbox' },
        children: [
          {
            name: 'Nieuwe Offerte',
            url: '/offertes/create'
          },
          {
            name: 'Overzicht',
            url: '/offertes'
          }
        ]
      },
      {
        name: 'Facturen',
        url: '/facturen',
        iconComponent: { name: 'cil-inbox' },
        children: [
          {
            name: 'Nieuwe Factuur',
            url: '/facturen/create'
          },
          {
            name: 'Overzicht',
            url: '/facturen'
          }
        ]
      },
      {
        name: 'Boeken',
        url: '/boeken',
        iconComponent: { name: 'cil-book' },
        children: [
          {
            name: 'Verkopen',
            url: '/boeken/verkopen'
          },
          {
            name: 'Aankopen',
            url: '/boeken/aankopen'
          },
          {
            name: 'Financieel',
            url: '/boeken/financieel'
          },
          {
            name: 'Uitg. Creditnota\'s',
            url: '/boeken/uitgaande-creditnotas'
          },
          {
            name: 'Ontv. Creditnota\'s',
            url: '/boeken/ontvangen-creditnotas'
          },
          {
            name: 'Inventaris',
            url: '/boeken/inventaris'
          }
        ]
      },
      {
        name: 'Klanten',
        url: '/klanten',
        iconComponent: { name: 'cil-contact' }
      },
      {
        name: 'Leveranciers',
        url: '/leveranciers',
        iconComponent: { name: 'cil-contact' }
      },
      {
        name: 'Klantenlisting',
        url: '/klantenlisting',
        iconComponent: { name: 'cil-description' }
      },
      {
        name: 'IC Listing',
        url: '/ic-listing',
        iconComponent: { name: 'cil-description' }
      },
      {
        name: 'Btw',
        url: '/btw',
        iconComponent: { name: 'cil-money' }
      }
    ];
  }
}
