export class Leverancier {
    _id: string;
    type: string;
    name: string;
    btwNr: string;
    contactpersoon: string;
    adresStraat: string;
    adresGemeente: string;
    postcode: string;
    land: string;
    email: string;
    telnr: string;
    leverancierSubscr: Date;
    iban: string;
    bic: string;
    rpr: string;

    constructor(name: string) {
        this.name = name;
    }
}
