import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appDate]',
  providers: [{provide: NG_VALIDATORS, useExisting: DateValidatorDirective, multi: true}]
})
export class DateValidatorDirective implements Validator {
  dateRegex = new RegExp('^(0[1-9]|1[012])[- /.] (0[1-9]|[12][0-9]|3[01])[- /.] (19|20)\d\d$');

  validate(control: AbstractControl): {[key: string]: any} | null {
    console.log('validate date', control.value)
    return control.value ? control.value.hasOwnProperty('year') && control.value.hasOwnProperty('month') && control.value.hasOwnProperty('day') : true;
  }
}
