import { AccountStripeService } from './account-stripe.sevice';
import { Component, OnInit, ViewChild, LOCALE_ID } from '@angular/core';
import { User } from '../../models/user';
import { UserService } from '../_services/user.service';
import { AlertService } from '../_services/alert.service';
import { map, take } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from "../_services/authentication.service";
import {FacturenService} from "../shared/services/facturen.service";
import {Factuur} from "../../models/Factuur";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  providers: [
   {provide: LOCALE_ID, useValue: 'nl-BE'}
  ]
})
export class AccountComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  currentUser: User;
  codeDisabled = true;
  picture: string;
  stripeTest: UntypedFormGroup;

  paymentOption = 'bancontact';

  expired = false;

  accountFacturen$ = new Observable<Factuur[]>();

  constructor(protected userService: UserService,
              protected alertService: AlertService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private stripeService: StripeService,
              private accountStripeService: AccountStripeService,
              private authService: AuthenticationService,
              private factuurService: FacturenService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser.role === 'Ondernemer') {
      this.expired = this.authService.accountExpired;
      this.accountFacturen$ = this.factuurService.getAllFacturen(this.currentUser)
      .pipe(
        map((facturen: Factuur[]) => facturen.filter(factuur => factuur.autoGenerated)),
        take(1)
      )
    }



    if(this.currentUser.role === 'Ondernemer' && !this.currentUser.bedrijf) {
      this.currentUser.bedrijf = {
        naam: null,
        straatNr: null,
        postCodeStad: null,
        btwNr: null,
        IBAN: null,
        BIC: null,
        Telefoon: null,
        RPR: null,
        logo: null
      }
    }

    console.log(this.router.parseUrl(this.router.url).queryParams['payment_intent_client_secret'])

    let payment_intent_client_secret = this.router.parseUrl(this.router.url).queryParams['payment_intent_client_secret'];
    if(payment_intent_client_secret) {
      this.stripeService.retrievePaymentIntent(payment_intent_client_secret)
      .pipe(take(1))
      .subscribe(response => {
        if(response.paymentIntent && response.paymentIntent.status === 'succeeded') {
          let currentExpiry = new Date(this.currentUser.accountExpiryDate);
          let newExpiry = new Date(this.currentUser.accountExpiryDate);
          newExpiry = new Date(new Date().setMonth(new Date().getMonth() + 1))
          console.log(currentExpiry, newExpiry)
          if(currentExpiry < newExpiry) {
            console.log('update expiry')
            this.currentUser.accountExpiryDate = newExpiry;
            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
            this.userService.update(this.currentUser)
            .pipe(take(1))
            .subscribe((user: User) => {
              this.authService.accountExpired = false;
              this.expired = false;
              this.router.navigate(['/account']);
              this.alertService.success('Betaling succesvol uitgevoerd')
              console.log('add factuur')
              let autoId = 'A-' + this.currentUser.firstName.substring(0,3) + this.currentUser.lastName.substring(0,3) + new Date().getDate() + new Date().getMonth() + new Date().getFullYear();
              this.factuurService.addFactuur(<any>{
                  "klantNaam": this.currentUser.firstName + ' ' + this.currentUser.lastName,
                  "klantAdres1": this.currentUser.bedrijf.straatNr,
                  "klantAdres2": this.currentUser.bedrijf.postCodeStad,
                  "btwNrKlant": this.currentUser.bedrijf.btwNr,
                  "bedrijfsNaam":"Lievens.dev",
                  "bedrijfsAdres1":"Kwaenijkstraat 23",
                  "bedrijfsAdres2":"3191 Hever",
                  "btwNr":"BE0656791948",
                  "IBAN":"BE78 6528 4843 0386",
                  "BIC":"BBRUBEBB",
                  "telefoon":"0494/25 82 48",
                  "factuurNummer":autoId,
                  "datum": new Date(),
                  "teBetalenVoor":new Date(),
                  "btwPercentage":"-1",
                  "rpr":"",
                  "leveringsDatum":null,
                  "opmerking": "Bijzondere vrijstellingsregeling kleine ondernemingen. Btw niet van toepassing.",
                  "artikels":[{"artikelNaam":"Verlenging account Adminboek","aantal":"1","prijs":"5.99","btw":"-1","nummer":"1"}],
                  autoGenerated: true
              }, false).toPromise().then(() => {
                return this.factuurService.addFactuur(<any>{
                  "klantNaam": this.currentUser.firstName + ' ' + this.currentUser.lastName,
                  "klantAdres1": this.currentUser.bedrijf.straatNr,
                  "klantAdres2": this.currentUser.bedrijf.postCodeStad,
                  "btwNrKlant": this.currentUser.bedrijf.btwNr,
                  "bedrijfsNaam":"Lievens.dev",
                  "bedrijfsAdres1":"Kwaenijkstraat 23",
                  "bedrijfsAdres2":"3191 Hever",
                  "btwNr":"BE0656791948",
                  "IBAN":"BE78 6528 4843 0386",
                  "BIC":"BBRUBEBB",
                  "telefoon":"0494/2 82 48",
                  "factuurNummer":autoId,
                  "datum": new Date(),
                  "teBetalenVoor": new Date(),
                  "user":"5a47a4ef36ba2e7916be47f8",
                  "btwPercentage":"-1",
                  "rpr":"",
                  "leveringsDatum":null,
                  "opmerking": "Bijzondere vrijstellingsregeling kleine ondernemingen. Btw niet van toepassing.",
                  "artikels":[{"artikelNaam":"Verlenging account Adminboek","aantal":"1","prijs":"5.99","btw":"-1","nummer":"1"}],
                  autoGenerated: true
                }, false).toPromise().then(() => {
                  this.accountFacturen$ = this.factuurService.getAllFacturen(this.currentUser)
                  .pipe(
                    map((facturen: Factuur[]) => facturen.filter(factuur => factuur.autoGenerated)),
                    take(1)
                  )
                })
              })
            })
          }
        } else {
          this.alertService.error('Er liep iets fout bij de betaling')
        }
      })
    }

  }


  base64textString = [];

  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));
    this.currentUser.bedrijf.logo = this.base64textString[0];
  }


  changeCode() {
    this.codeDisabled = false;
  }

  submitCode() {
    this.userService.update(this.currentUser)
    .pipe(take(1))
    .subscribe(user => this.codeDisabled = true, error => this.alertService.error(error.error));
  }

  saveBedrijf() {
    this.userService.update(this.currentUser)
    .pipe(take(1))
    .subscribe((result: any) => {
      this.currentUser.bedrijf = result.value.bedrijf;
      localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
      this.alertService.success('Nieuwe bedrijfsgegevens opgeslaan');
    }, error => this.alertService.error(error.message));
  }



  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'nl'
  };


  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  downloadFactuur(factuur: Factuur) {
    return this.router.navigate(['facturen/edit/' + factuur._id], { queryParams: {'download': true}});
  }

  createCheckout(): void {
    this.accountStripeService.pay().subscribe(async (data) => {
      console.log(data);
      if (this.paymentOption == 'bancontact') {
          // confirm creditcard payment
          this.stripeService
              .confirmBancontactPayment(data.toString(), {
                  payment_method: {
                      billing_details: {
                          name: this.currentUser.firstName + ' ' + this.currentUser.lastName,
                          email: this.currentUser.username
                      },
                  },
                  return_url: 'https://www.enkelvoudigboekhouden.be/account'
              })
              .pipe(take(1))
              .subscribe((result) => {
                  console.log('result', result);
              });
      }

  });
  }

}
