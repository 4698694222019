import { NumberDirective } from './_validators/number.validator';
import { BICValidatorDirective } from './_validators/bic.validator';
import { IBANValidatorDirective } from './_validators/iban.validator';
import { BtwValidatorDirective } from './_validators/btw.validator';
import { DateValidatorDirective } from './_validators/date.validator';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { AlertComponent } from './directives/alert/alert.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from './_services/authentication.service';
import { UserService } from './_services/user.service';
import { AuthGuard } from './guards/auth.guard';
import { routing } from './app.routing';
import { KlantComponent } from './klant/klant.component';
import { LeverancierComponent } from './leverancier/leverancier.component';
import { KlantService } from './klant/klant.service';
import { KlantEditComponent } from './klant/klant-edit/klant-edit.component';
import { LeverancierEditComponent } from './leverancier/leverancier-edit/leverancier-edit.component';
import { LeverancierService } from './leverancier/leverancier.service';

import { Angulartics2Module } from 'angulartics2';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BtwOverviewComponent } from './btw-overview/btw-overview.component';
import { AccountComponent } from './account/account.component';
import { PaginationService } from './_services/pagination/pagination.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';

import { registerLocaleData } from '@angular/common';
import localeNlBE from '@angular/common/locales/nl-BE';
import localeNlBEExtra from '@angular/common/locales/extra/nl-BE';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { KlantenlistingComponent } from './klantenlisting/klantenlisting.component';
import { IcListingComponent } from './ic-listing/ic-listing.component';
import { NgxStripeModule } from 'ngx-stripe';
import {
  BreadcrumbComponent,
  BreadcrumbModule, ButtonModule, CardBodyComponent, CardComponent, CardHeaderComponent,
  ContainerComponent,
  FooterModule, FormCheckInputDirective, FormCheckLabelDirective, FormControlDirective, FormSelectDirective,
  GridModule,
  HeaderModule, InputGroupComponent,
  SidebarModule, TableColorDirective, TableDirective
} from "@coreui/angular";
import {NgScrollbar} from "ngx-scrollbar";
import {IconModule} from "@coreui/icons-angular";
import {AankoopService} from "./shared/services/aankoop.service";
import {VerkoopService} from "./shared/services/verkoop.service";
import {FinancieelService} from "./modules/boeken/financieel/financieel.service";
import {OntvCreditnotaService} from "./shared/services/ontv-creditnota.service";
import {UitgCreditnotaService} from "./shared/services/uitg-creditnota.service";
import {OffertesService} from "./shared/services/offertes.service";
import {FacturenService} from "./shared/services/facturen.service";
import {ProductenService} from "./modules/boeken/inventaris/producten.service";

registerLocaleData(localeNlBE, 'nl-BE', localeNlBEExtra);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AlertComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    KlantComponent,
    LeverancierComponent,
    KlantEditComponent,
    LeverancierEditComponent,
    BtwOverviewComponent,
    AccountComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DateValidatorDirective,
    BtwValidatorDirective,
    IBANValidatorDirective,
    BICValidatorDirective,
    NumberDirective,
    KlantenlistingComponent,
    IcListingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    routing,
    Angulartics2Module.forRoot(),
    NgbModule,
    SharedModule,
    NgxChartsModule,
    NgHttpLoaderModule.forRoot(),
    ReactiveFormsModule,
    //NgxStripeModule.forRoot('pk_test_51I7mN9E5Br8UeQ3UuNdDUv6YNFH9Ubk2zAMsMkHL5kFXcNcyXlgYlPcJ70sATkxCbR92tGfWzQefAuGzAi1DHhOk00pjMyNbRA')
    NgxStripeModule.forRoot('pk_live_51I7mN9E5Br8UeQ3UKQu0L6jqiKzMjYzBh7dvcDyQsSxYxVPnj6x8jjimgv3q0PFFvMWFdAfMZtOka0ft2wCAsUYn00cJ37ak5s'),
    SidebarModule,
    NgScrollbar,
    IconModule,
    BreadcrumbModule,
    HeaderModule,
    FooterModule,
    GridModule,
    ButtonModule,
    CardComponent,
    CardHeaderComponent,
    CardBodyComponent,
    FormSelectDirective,
    TableDirective,
    TableColorDirective,
    InputGroupComponent,
    FormControlDirective,
    FormCheckInputDirective,
    FormCheckLabelDirective
  ],
  providers: [
    VerkoopService,
    AankoopService,
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    KlantService,
    LeverancierService,
    FinancieelService,
    OffertesService,
    FacturenService,
    OntvCreditnotaService,
    UitgCreditnotaService,
    ProductenService,
    PaginationService,
    {provide: 'DEFAULT_ENTITY', useValue: 'unknown'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
  },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
