import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LeverancierService } from '../leverancier.service';
import { Location } from '@angular/common';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { I18n, CustomDatepickerI18n } from "../../ngb-date-fr-parser-formatter/ngb-i18n"
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "../../ngb-date-fr-parser-formatter/ngb-date-fr-parser-formatter"
import { GeneralComponent } from '../../general/general.component';
import { debounceTime, switchMap, take } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-leverancier-edit',
  templateUrl: './leverancier-edit.component.html',
  providers: [
    I18n,
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ]
})
export class LeverancierEditComponent extends GeneralComponent implements OnInit {
  @ViewChild('leverancierForm', { static: true }) ngForm: NgForm;
  leverancier;
  isEdit = false;

  constructor(protected leverancierService: LeverancierService,
              private location: Location,
              private route: ActivatedRoute) {
                super();
              }

  ngOnInit() {
    let param = this.route.snapshot.params['id'];
    if(param){
      this.isEdit = true;
      this.leverancierService.getLeverancier(param)
      .pipe(take(1))
      .subscribe(leverancier =>
        {
          this.leverancier = leverancier
          this.leverancier.leverancierSubscr = this.convertInputDate(this.leverancier.leverancierSubscr);
        });
    }
    // new leverancier
    this.leverancier = {
      type: '',
      name: '',
      btwNr: '',
      contactpersoon: '',
      email: '',
      leverancierSubscr: null,
      adresStraat: '',
      adresGemeente: '',
      postcode: '',
      land: '',
      iban: '',
      bic: '',
      rpr: ''
    }
  }

  navigateAway(){
    this.location.back();
  }

  public onSubmit() {
    this.leverancier.leverancierSubscr = this.convertOutputDate(this.leverancier.leverancierSubscr);

    const addOrUpdateLeverancier = this.isNew(this.leverancier) ?
    this.leverancierService.addLeverancier(this.leverancier) :
    this.leverancierService.updateLeverancier(this.leverancier)

    addOrUpdateLeverancier
    .pipe(take(1))
    .subscribe(
      response => {
        this.location.back();
      },
    );
  }

}
