import { IcListingComponent } from './ic-listing/ic-listing.component';
import { KlantenlistingComponent } from './klantenlisting/klantenlisting.component';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './guards/auth.guard';
import { KlantComponent } from './klant/klant.component';
import { LeverancierComponent } from './leverancier/leverancier.component';
import { KlantEditComponent } from './klant/klant-edit/klant-edit.component';
import { LeverancierEditComponent } from './leverancier/leverancier-edit/leverancier-edit.component';
import { BtwOverviewComponent } from './btw-overview/btw-overview.component';
import { AccountComponent } from './account/account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


const appRoutes: Routes = [
   { data: { title: 'Home' }, path: '', component: HomeComponent, canActivate: [AuthGuard] },
   { data: { title: 'Facturen' }, path: 'facturen', loadChildren: () => import('./modules/facturen/facturen.module').then(m => m.FacturenModule)},
   { data: { title: 'Offertes' }, path: 'offertes', loadChildren: () => import('./modules/offertes/offertes.module').then(m => m.OffertesModule)},
   { data: { title: 'Boeken' }, path: 'boeken', loadChildren: () => import('./modules/boeken/boeken.module').then(m => m.BoekenModule)},
   { data: { title: 'Login' }, path: 'login', component: LoginComponent },
   { data: { title: 'Registreren' }, path: 'register', component: RegisterComponent },
   { data: { title: 'Wachtwoord vergeten' }, path: 'forgot_password', component: ForgotPasswordComponent},
   { data: { title: 'Wachtwoord resetten' }, path: 'reset_password', component: ResetPasswordComponent},
   { data: { title: 'Klanten' }, path: 'klanten', component: KlantComponent, canActivate: [AuthGuard] },
   { data: { title: 'Klant aanmaken' }, path: 'klant/create', component: KlantEditComponent, canActivate: [AuthGuard]},
   { data: { title: 'Klant bewerken' }, path: 'klant/edit/:id', component: KlantEditComponent, canActivate: [AuthGuard]},
   { data: { title: 'Leveranciers' }, path: 'leveranciers', component: LeverancierComponent, canActivate: [AuthGuard]},
   { data: { title: 'Leverancier bewerken' }, path: 'leverancier/edit/:id', component: LeverancierEditComponent, canActivate: [AuthGuard]},
   { data: { title: 'Leverancier aanmaken' }, path: 'leverancier/create', component: LeverancierEditComponent, canActivate: [AuthGuard]},
   { data: { title: 'Btw' }, path: 'btw', component: BtwOverviewComponent, canActivate:[AuthGuard]},
   { data: { title: 'Account' }, path: 'account', component: AccountComponent, canActivate:[AuthGuard]},
   { data: { title: 'Klantenlisting' }, path: 'klantenlisting', component: KlantenlistingComponent, canActivate: [AuthGuard]},
   { data: { title: 'IC Listing' }, path: 'ic-listing', component: IcListingComponent, canActivate: [AuthGuard]},

   // otherwise redirect to home
   { title: 'Home', path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled'
});
