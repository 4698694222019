<div class="container mt-5">
    <div class="row mb-5">
        <div class="col-12 col-md-5">
            <h2>Start een vereenvoudigde boekhouding</h2>

            <p>Adminboek is een pakket om een vereenvoudigde boekhouding te voeren. Bekijk onze handige
              <strong><a href="https://www.youtube.com/playlist?list=PLsp1QrxdpDEVcdHWIT2aLfTtPfskH_RYi" target="_blank">instructievideo's</a></strong> en ontdek hoe u offertes, facturen en al uw verrichtingen kan bijhouden.
            </p>
            <p>
              Het pakket biedt volgende administratie aan:
            </p>
              <ul>
                <li>Offertes</li>
                <li>Facturen</li>
                <li>Klanten en leveranciers</li>
                <li>Aankopen en verkopen</li>
                <li>Financieel dagboek</li>
                <li>Ontvangen en uitgaande creditnota's</li>
                <li>Inventaris</li>
                <li>Btw klantenlisting</li>
                <li>Btw overzicht</li>
                <li>Intracommunautaire listing</li>
              </ul>
              <p>
                <strong><i>Adminboek is alleen voor educatief gebruik.</i></strong>
              </p>
        </div>
        <div class="col-12 col-md-7">
          <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3>Inloggen</h3>
              </div>
              <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
              <div class="card-body" style="min-height:245px;">
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                        <label for="username">E-mail</label>
                        <input type="email" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required />
                        <div *ngIf="f.submitted && !username.valid" class="help-block error">E-mail is verplicht</div>
                    </div>
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                        <label for="password">Paswoord</label>
                        <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
                        <div *ngIf="f.submitted && !password.valid" class="help-block error">Password is verplicht</div>
                    </div>
              </div>
              <div class="card-footer">
                <button type="submit" [disabled]="loading || !f.form.valid" class="btn btn-primary">Login</button>
                <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
                <a [routerLink]="['/forgot_password']" class="btn btn-link float-right">Wachtwoord vergeten</a>

                <button class="float-end" routerLink="/register" cButton>Registreer</button>
              </div>
            </form>
            </div>
          </div>
          </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row" style="border-top:2px darkgray solid;">
        <div class="col-12 p-0">
            <ngb-carousel>
                  <ng-template ngbSlide>
                    <div class="dashboard-img"></div>
                    <div class="carousel-caption">
                      <h5>Dashboard</h5>
                      <p>Overzicht van uw aan- en verkopen over verschillende jaren</p>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <div class="facturen-img"></div>
                    <div class="carousel-caption">
                      <h5>Facturen en offertes</h5>
                      <p>Maak op een eenvoudige en snelle manier uw facturen en offertes aan.</p>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <div class="leveranciers-img"></div>
                    <div class="carousel-caption">
                      <h5>Klanten & Leveranciers</h5>
                      <p>Beheer uw klanten en leveranciers.</p>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <div class="inventaris-img"></div>
                    <div class="carousel-caption">
                      <h5>Inventaris</h5>
                      <p>Organiseer uw productgroepen en producten.</p>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <div class="verkopen-img"></div>
                    <div class="carousel-caption">
                      <h5>Verrichtingen</h5>
                      <p>Registreer uw aankopen, verkopen, creditnota's en financieel dagboek.</p>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <div class="btw-overzicht-img"></div>
                    <div class="carousel-caption">
                      <h5>Btw overzicht</h5>
                      <p>Eenvoudig uw btw-aangifte zelf doen? Raadpleeg het btw overzicht.</p>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <div class="klantenlisting-img"></div>
                    <div class="carousel-caption">
                      <h5>Klantenlisting</h5>
                      <p>Aan de hand van de klantenlisting kan u op een snelle manier uw jaarlijkse listing indienen.</p>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <div class="iclisting-img"></div>
                    <div class="carousel-caption">
                      <h5>IC Listing</h5>
                      <p>Een overzicht van uw jaarlijkse intracommunautaire handelingen.</p>
                    </div>
                </ng-template>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
            </ngb-carousel>
          </div>
    </div>
</div>
<div class="container-fluid" style="background-color:#FFFAFF;" class="mb-4">
    <div class="container">
      <div class="row pb-5">
        <div class="col-12 col-lg-8 mt-5 mb-5">
          <div class="card" id="instructievideos">
            <div class="card-header">
              <h4>Instructievideo's</h4>
            </div>
            <div class="card-body">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLsp1QrxdpDEVcdHWIT2aLfTtPfskH_RYi" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
              <i>Klik op het icoon rechtsboven in de video om een andere video in de playlist te selecteren</i>
            </div>
          </div>
          <div class="card mt-3">
           <div class="card-header">
        <h4>Wanneer mag ik een enkelvoudige/vereenvoudigde boekhouding gebruiken?</h4>
      </div>
        <div class="card-body">
        <blockquote class="blockquote" style="font-size:1rem;">
          <p class="mb-0">
            Een vereenvoudigde (ook 'enkelvoudige' of 'eenvoudige') boekhouding mag
            door kleine ondernemingen (eenmanszaken, vennootschappen onder firma en
            gewone commanditaire vennootschappen met een jaaromzet, exclusief btw,
            die kleiner is dan 500.000 euro) worden gevoerd. Het mag weliswaar niet
            in enkele sectoren: verzekeringen, kredietverrichtingen, beleggingen en
            beursverrichtingen.
          </p>
          <p>
            De vereenvoudigde boekhouding bestaat uit het bijhouden van:
          </p>
          <ul>
            <li>
              een financieel dagboek, dat u kunt splitsen in een kas- en bankboek. Het kasboek omvat alle uitgaven en ontvangsten in geld, het bankboek omvat alle uitgaven en ontvangsten via een bankrekening
            </li>
            <li>
              een aankoopboek, met de facturen en creditnota's van uw leveranciers
            </li>
            <li>
              een verkoopboek, met de facturen en creditnota's aan uw klanten
            </li>
            <li>
              een inventarisboek, dat een beschrijving en waardering van de voorraad omvat.
            </li>
          </ul>
          <footer class="blockquote-footer"><a href="https://www.vlaanderen.be/boekhouding" target="_blank">Vlaanderen</a></footer>
        </blockquote>
      </div>
      </div>
      </div>
      <div class="col-12 col-lg-4 mt-5">
        <div class="handelenwij">
        </div>
      </div>
      </div>
    </div>
  </div>
