import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[appIBAN]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: IBANValidatorDirective, multi: true}]
})
export class IBANValidatorDirective implements AsyncValidator {

  lastValue = null;

  constructor(private http: HttpClient) {
  }

  validate(control: AbstractControl): Observable<ValidationErrors|null> {
    if(control.value && control.value.length) {
    return timer(500).pipe(
      switchMap(() => {
        if(this.lastValue !== control.value.replace(/\s/g, "")) {
          return this.validateIBAN(control.value.replace(/\s/g, ""), control)
        } else {
          return of(null);
        }
      })
    )
    } else {
      return of(null)
    }
  }

  validateIBAN(iban, control: AbstractControl) {
    this.lastValue = control.value.replace(/\s/g, "");
    let URI = '/api/klant/iban-check';
    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    let body = {iban: iban};
    return this.http.post(URI, body, {headers: headers})
    .pipe(map(res => {
      if(res) {
        control.setValue(res)
        return null;
      } else {
        return {'iban': res}
      }
    }));
  }
}








