import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { TableFilterMenuComponent } from '../shared/table-filter-menu/table-filter-menu.component';
import { combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import {VerkoopService} from "../shared/services/verkoop.service";
import {UitgCreditnotaService} from "../shared/services/uitg-creditnota.service";
import {Verkoop} from "../../models/Verkoop";
import {UitgCreditNota} from "../../models/Creditnota";
import {Klant} from "../../models/Klant";

@Component({
  selector: 'app-ic-listing',
  templateUrl: './ic-listing.component.html'
})
export class IcListingComponent implements OnInit, AfterViewInit {

  @ViewChild(TableFilterMenuComponent) jaarFilter: TableFilterMenuComponent;

  klantenIds = [];

  enabled;


  constructor(private verkopenService: VerkoopService,
              private uitgCreditNotaService: UitgCreditnotaService,
              private router: Router) {
                this.enabled = this.router.parseUrl(this.router.url).queryParams['extraFeatures']
               }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const combinedObservables$ = combineLatest(
      this.verkopenService.getAllVerkopen(currentUser),
      this.uitgCreditNotaService.getAllUitgCreditNotas(currentUser)
      );
    this.jaarFilter.jaar$
    .pipe(
      switchMap(() => combinedObservables$))
    .subscribe((response: any) => {
      const verkopen: Verkoop[] = response[0];
      const creditNotas: UitgCreditNota[] = response[1];

      this.klantenIds = [];
      let _verkopen = verkopen.filter(item => {
        const itemDatum: Date = new Date(item.datum);
        return itemDatum.getFullYear() === parseInt(this.jaarFilter.jaar$.getValue().toString()) && item.intracommunautair;
      });

      let _creditNotas = creditNotas.filter(item => {
        const itemDatum: Date = new Date(item.datum);
        return itemDatum.getFullYear() === parseInt(this.jaarFilter.jaar$.getValue().toString()) && item.intracommunautair;
      });

      _verkopen.forEach(verkoop => {
       if(this.klantenIds.findIndex((id: any) => id.klant._id === verkoop.klant._id) === -1) {
         let icTypes = ['Diensten', 'Levering', 'Driehoeksverkeer'];
         icTypes.forEach((type) => {
         this.klantenIds.push({klant: verkoop.klant, verkopen: [], creditNotas: [], totaal: 0, creditTotaal: 0, btwTotaal: 0, list: false, icType: type});
         })
       }
      });

      _creditNotas.forEach(creditNota => {
        if(this.klantenIds.findIndex((id: any) => id.klant._id === creditNota.klant._id) === -1) {
          let icTypes = ['Diensten', 'Levering', 'Driehoeksverkeer'];
          icTypes.forEach((type) => {
          this.klantenIds.push({klant: creditNota.klant, verkopen: [], creditNotas: [], totaal: 0, creditTotaal: 0, btwTotaal: 0, list: false, icType: type});
          })
        }
       });

       console.log(this.klantenIds)

      this.klantenIds.forEach(id => {
        console.log(_verkopen)
        _verkopen.forEach(verkoop => {
          if (verkoop.klant._id === id.klant._id && id.icType === verkoop.icType) {
            id.verkopen.push(verkoop)
          }
        });

        _creditNotas.forEach(creditNota => {
          if (creditNota.klant._id === id.klant._id && id.icType === creditNota.icType) {
            id.creditNotas.push(creditNota)
            id.creditTotaal += creditNota.bedragExclBTW;
          }
        })
      });

      this.klantenIds.forEach(id => {
        let klantTotaal = 0;
        let btwTotaal = 0;
        id.verkopen.forEach((_verkoop: Verkoop) => {
          if (_verkoop.intracommunautair) {
            klantTotaal += _verkoop.bedragExclBTW;
            btwTotaal += _verkoop.bedragInclBTW - _verkoop.bedragExclBTW;
            id.list = true;
          }
        });
        id.creditNotas.forEach((_creditNota: UitgCreditNota) => {
          if(_creditNota.intracommunautair) {
            id.list = true;
          }
        })
        id.totaal = klantTotaal;
        id.btwTotaal = btwTotaal;
      });
    });
  }

  get totaalICListing() {
    let totaal = 0;
    this.klantenIds.forEach(klantenId => {
      klantenId.verkopen.forEach(verkoop => {
        if (verkoop.intracommunautair) {
          totaal += verkoop.bedragExclBTW;
        }
      });

      klantenId.creditNotas.forEach(creditNota => {
        if (creditNota.intracommunautair) {
          totaal -= creditNota.bedragExclBTW;
        }
      })
    });
    return totaal;
  }

  get totaalBTW() {
    let totaal = 0;
    this.klantenIds.forEach(klantenId => {
      klantenId.verkopen.forEach(verkoop => {
        if (verkoop.intracommunautair) {
          totaal += verkoop.bedragInclBTW - verkoop.bedragExclBTW;
        }
      });
    });
    return totaal;
  }

    protected readonly Klant = Klant;
}
