<c-container>
  <c-card class="mt-4 mb-4">
    <c-card-header>Registreren</c-card-header>
    <c-card-body>
      <form name="form" (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
          <label for="firstName">Voornaam <span *ngIf="!firstName.valid && firstName.errors?.required" class="error"><strong>*</strong></span></label>
          <input type="text" class="form-control" name="firstName" [(ngModel)]="model.firstName" #firstName="ngModel" required />
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
          <label for="lastName">Familienaam <span *ngIf="!lastName.valid && lastName.errors?.required" class="error"><strong>*</strong></span></label>
          <input type="text" class="form-control" name="lastName" [(ngModel)]="model.lastName" #lastName="ngModel" required />
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
          <label for="username">E-mail <span *ngIf="!username.valid && username.errors?.required" class="error"><strong>*</strong></span></label>
          <input type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required />
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
          <label for="password">Paswoord <span *ngIf="!password.valid && password.errors?.required" class="error"><strong>*</strong></span></label>
          <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !passwordConfirm.valid }">
          <label for="passwordConfirm">Paswoord Bevestiging <span *ngIf="!passwordConfirm" class="error"><strong>*</strong></span></label>
          <input type="password" class="form-control" name="passwordConfirm" [(ngModel)]="passwordConfirm" #passwordConfirmControl="ngModel" required />
          <div *ngIf="passwordConfirm && !isPasswordEqual() && passwordConfirmControl.dirty" class="help-block error">Paswoord komt niet overeen</div>
        </div>
        <div class="form-group">
          <label for="role">Gebruikerstype <span *ngIf="!userType.valid && userType.errors?.required" class="help-block error"><strong>*</strong></span></label>
          <select cSelect name="role" class="form-control" [(ngModel)]="model.role" (ngModelChange)="userTypeChange()" required #userType="ngModel">
            <option [value]="'Student'" selected>Student</option>
            <option [value]="'Leerkracht'">Leerkracht</option>
          </select>
        </div>
        <div class="form-group" *ngIf="model.role === 'Leerkracht' || model.role === 'Student'">
          <label *ngIf="model.role === 'Student'" for = "leerKrachtCode">Vul de code van je leerkracht in. <span *ngIf="!leerkrachtCode.valid && leerkrachtCode.errors?.required && model.role ==='Student'" class="error"><strong>*</strong></span></label>
          <label *ngIf="model.role === 'Leerkracht'" for="leerkrachtCode">Uw code. Geef deze code aan uw leerlingen om ze te linken.</label>
          <input name="leerkrachtCode" class="form-control" [disabled]="model.role === 'Leerkracht'" [(ngModel)]="model.leerkrachtCode" #leerkrachtCode="ngModel"
                 [required]="model.role ==='Student'"/>
          <div class="row mt-2">
            <div class="col-12">
              <button *ngIf="model.role === 'Student'" type="button" class="btn btn-primary" [class.btn-success]="leerkrachtValid" (click)="validateLeerkracht(model.leerkrachtCode)"><i class="fa fa-check" style="color:white;" *ngIf="leerkrachtValid"></i> Valideer leerkracht</button>
              <div *ngIf="!leerkrachtCode.errors?.required && leerkrachtValidated && !leerkrachtValid && model.role ==='Student'" class="d-inline-block ml-2 error">Leerkracht niet gevonden.</div>
              <div *ngIf="leerkrachtValidated && leerkrachtValid && model.role ==='Student'" class="d-inline-block ml-2">Jouw leerkracht is <strong>{{leerkracht.firstName}} {{leerkracht.lastName}}</strong>.</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div id="accordion" role="tablist" class="mt-5 mb-3">
            <div class="card">
              <div class="card-header" role="tab" id="headingOne">
                <h5 class="mb-0">
                  <a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Cookies
                  </a>
                </h5>
              </div>

              <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                  <p>Deze applicatie maakt gebruik van cookies om uw sessie bij te houden en automatisch in te loggen.</p>
                </div>
              </div>
            </div>
          </div>

          <c-form-check>
            <input cFormCheckInput type="checkbox" name="agreed" [(ngModel)]="agreed">
            <label cFormCheckLabel class="ms-2">Ik ga akkoord</label>
          </c-form-check>
        </div>


        <div class="form-group mt-2">
          <button [disabled]="loading || !agreed || !f.form.valid || !isPasswordEqual() || ((model.role === 'Student') ? !leerkrachtValid : false)" class="btn btn-primary">Registreer</button>
          <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
      </form>
    </c-card-body>
  </c-card>
</c-container>
