import { User } from './../../models/user';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
    accountExpired = false;

    constructor(private http: HttpClient) {
      let user = JSON.parse(localStorage.getItem('currentUser'));

      this.checkExpiry(user);
    }

    login(username: string, password: string) {
        return this.http.post('/users/authenticate', { username: username, password: password })
            .pipe(map((response) => {
                // login successful if there's a jwt token in the response
                const user: any = response;

                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    console.log(user);
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }

                this.checkExpiry(user);

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.clear();
    }

    checkExpiry(user: User) {
      if(user && user.role === 'Ondernemer') {
        let today = new Date();
        let expiryDate = new Date(user.accountExpiryDate);
        if(today > expiryDate) {
          this.accountExpired = true;
        } else {
          this.accountExpired = false;
        }
      }
    }
}
