import { Component, OnInit, DoCheck } from '@angular/core';
import { User } from '../../models/user';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, DoCheck {
  currentUser: User;
  isNavbarCollapsed = true;

  constructor(protected authenticationService: AuthenticationService) {
    this.currentUser =JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
  }

  ngDoCheck() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  logout() {
    this.authenticationService.logout();
  }

  get expired() {
    return this.authenticationService.accountExpired;
  }

}
