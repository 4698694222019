import { Injectable, OnInit } from '@angular/core';

@Injectable()
export class PaginationService implements OnInit {

  rowsOnPage = 5;
  activePage = 1;

  paginatedComponents = [];

  constructor() {
  }

  ngOnInit() {
  }

  initPagination(entity) {
    let found = this.paginatedComponents.find(component => component.id === entity);
    if(!found) {
      this.paginatedComponents.push(new PaginatedEntity(entity, 5, 1))
    }
  }

  getNrOfItems(entity){
    let found = this.paginatedComponents.find(component => component.id === entity);
    return found.rowsOnPage
  }

  getActivePage(entity){
    let found = this.paginatedComponents.find(component => component.id === entity);
    return found ? found.activePage : 1;
  }

  setRowsOnPage(entity, nrOfRows){
    let found = this.paginatedComponents.find(component => component.id === entity);
    found.rowsOnPage = nrOfRows
  }

  setCurrentPage(entity, activePage){
    let found = this.paginatedComponents.find(component => component.id === entity);
    found.activePage = activePage;
  }

}

export class PaginatedEntity {
  id;
  rowsOnPage;
  activePage;

  constructor(id, rowsOnPage, activePage) {
    this.id = id;
    this.rowsOnPage = rowsOnPage;
    this.activePage = activePage;
  }
}
