import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Klant } from '../../models/Klant';
import { User } from '../../models/user';
import { map } from 'rxjs/operators';

@Injectable()
export class KlantService {


  constructor(private http: HttpClient) {

  }

  addKlant(klant: Klant){
    let URI = '/api/klant/';
    let headers = new HttpHeaders;
    let body = {
      type: klant.type,
      name: klant.name,
      btwNr: klant.btwNr,
      contactpersoon: klant.contactpersoon,
      email: klant.email,
      klantSubscr: klant.klantSubscr,
      land: klant.land,
      telnr: klant.telnr,
      adresStraat: klant.adresStraat,
      adresGemeente: klant.adresGemeente,
      postcode: klant.postcode,
      iban: klant.iban,
      bic: klant.bic,
      rpr: klant.rpr
    };
    headers.append('Content-Type', 'application/json');
    return this.http.post(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

  getAllKlanten(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/klanten/' + currentUser._id, {headers: headers})
    .pipe(map(res => res));
  }

  getKlant(klantId: string){
    return this.http.get('/api/klant/' + klantId)
    .pipe(map(res => res));
  }

  updateKlant(klant: Klant){
    let URI = `/api/klant/${klant._id}`;
    let headers = new HttpHeaders;
    let body = {
      type: klant.type,
      name: klant.name,
      btwNr: klant.btwNr,
      contactpersoon: klant.contactpersoon,
      adresStraat: klant.adresStraat,
      adresGemeente: klant.adresGemeente,
      postcode: klant.postcode,
      land: klant.land,
      email: klant.email,
      telnr: klant.telnr,
      klantSubscr: klant.klantSubscr,
      iban: klant.iban,
      bic: klant.bic,
      rpr: klant.rpr
    }
    headers.append('Content-Type', 'application/json');
    return this.http.put(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

  removeKlant(klantId: string){
    let URI = `/api/klant/${klantId}`;
    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.delete(URI, {headers: headers})
    .pipe(map(res => res));
  }
}
