import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AlertService } from '../_services/alert.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  newPassword;
  verifyPassword;

  constructor(protected http: HttpClient, protected alertService: AlertService) { }

  ngOnInit() {
  }

  resetPasswordClicked(event) {
    let token = document.location.href.split('token=')[1];
    let params = {newPassword: this.newPassword, verifyPassword: this.verifyPassword, token: token};
    this.http.post('/users/reset_password', params).toPromise().then((result: any) => {
      if(result) {
        this.alertService.success('Wachtwoord werd succesvol ingesteld')
      }
    },
    error => {
      this.alertService.error('Er liep iets mis bij het instellen van je nieuwe wachtwoord')
    })
}

}
