<div class="row">
  <div class="col-12 col-md-6">
    <span>Items per pagina:</span>
    <div class="btn-group ms-2">
      <div class="btn btn-primary" [ngClass]="{'active' : nrOfItems === 10}" (click)="nrOfItems = 10">10</div>
      <div class="btn btn-primary" [ngClass]="{'active' : nrOfItems === 25}" (click)="nrOfItems = 25">25</div>
      <div class="btn btn-primary" [ngClass]="{'active' : nrOfItems === 50}" (click)="nrOfItems = 50">50</div>
    </div>
  </div>
  <div class="col-12 col-md-6 text-end">
    <span>Pagina: </span>
    <div class="btn-group ms-2">
      <div class="btn btn-primary" *ngFor="let pageNumber of pagesArray"
        [ngClass]="{'active' : pageNumber === pageNumberValue}"
        (click)="setPage(pageNumber)">
        {{pageNumber}}
      </div>
    </div>
  </div>
</div>
