export class Klant {
    _id: string;
    type: string;
    name: string;
    btwNr: string;
    contactpersoon: string;
    adresStraat: string;
    adresGemeente: string;
    postcode: string;
    land: string;
    email: string;
    telnr: string;
    klantSubscr: Date;
    iban: string;
    bic: string;
    rpr: string;

    constructor(name: string) {
        this.name = name;
    }
}

export const preprovisionedKlanten = [
  { type: 40001,	name: 'Pagode',	adresStraat: 'Stationsstraat 15',	adresGemeente: '9900 Eeklo',	btwNr: 'BE 0405 490 781', rpr :	'Gent',	iban: 'BE55 4479 6260 1144', bic:	'KRED BE BB' },
  { type: 40002,	name: 'Karina',	adresStraat: 'Kasteelstraat 54',	adresGemeente: '9700 Oudenaarde',	btwNr: 'BE 0589 004 259', rpr :	'Gent, afd. Oudenaarde',	iban: 'BE95 2850 4932 7458', bic:	'GEBA BE BB' },
  { type: 40003,	name: 'Urban Games',	adresStraat: 'Wapenplein 21',	adresGemeente: '8790 Waregem',	btwNr: 'BE 0407 609 341', rpr :	'Gent, afd. Kortrijk',	iban: 'BE44 3850 3638 2945', bic:	'BBRU BE BB' },
  { type: 40004,	name: 'Allessandro',	adresStraat: 'Bergstraat 78',	adresGemeente: '9240 Zele',	btwNr: 'BE 0430 855 984', rpr :	'Gent, afd. Dendermonde',	iban: 'BE26 3930 1484 4729', bic:	'BBRU BE BB' },
  { type: 40005,	name: 'Saxoline',	adresStraat: 'Lavendellaan 62',	adresGemeente: '1170 Brussel',	btwNr: 'BE 0408 236 079', rpr :	'Brussel',	iban: 'BE13 2100 8825 9039', bic:	'GEBA BE BB' },
  { type: 40006,	name: 'Nappa',	adresStraat: 'Pelgrimsweg 12',	adresGemeente: '1745 Opwijk',	btwNr: 'BE 0421 303 068', rpr :	'Brussel',	iban: 'BE86 7374 2409 4450', bic:	'KRED BE BB' },
  { type: 40007,	name: 'Eenhoorn',	adresStraat: 'Speelkaartenstraat 96',	adresGemeente: '2300 Turnhout',	btwNr: 'BE 0434 806 656', rpr :	'Antwerpen, afd. Turnhout',	iban: 'BE87 4324 0217 6594', bic:	'KRED BE BB' },
  { type: 40008,	name: 'Tamaris',	adresStraat: 'Zwinlaan 53',	adresGemeente: '8300 Knokke',	btwNr: 'BE 0438 965 184', rpr :	'Gent, afd. Brugge',	iban: 'BE34 0682 2418 3090', bic:	'GKCC BE BB' },
  { type: 40009,	name: 'Salomon',	adresStraat: 'Torenstraat 81',	adresGemeente: '2240 Zandhoven',	btwNr: 'BE 0456 145 369', rpr :	'Antwerpen',	iban: 'BE89 0000 0987 3485', bic:	'BPOT E B1' },
  { type: 40010,	name: 'Red Label',	adresStraat: 'Dennenbosstraat 33',	adresGemeente: '3640	Kinrooi',	btwNr: 'BE 0418 740 387', rpr :	'Antwerpen, afd. Tongeren',	iban: 'BE67 7370 0262 3487', bic:	'KRED BE BB' },
  { type: 40011,	name: 'Caprice',	adresStraat: 'Koekoekstraat 5',	adresGemeente: '9090 Melle',	btwNr: 'BE 0449 865 214	30', rpr :	'Gent',	iban: 'BE24 2900 1936 2938', bic:	'GEBA BE BB' },
  { type: 40012,	name: 'Jorelle',	adresStraat: 'Verdunstraat 7',	adresGemeente: '1020 Brussel (Laken)',	btwNr: 'BE 0461 408 709', rpr :	'Brussel',	iban: 'BE19 3100 1476 5712', bic:	'BBRU BE BB' },
  { type: 40013,	name: 'Alyssa',	adresStraat: 'Menenpoortstraat 89',	adresGemeente: '8800 Roeselare	BE',	btwNr: 'BE 0426 952 032	30', rpr :	'Gent, afd. Kortrijk',	iban: 'BE42 4427 0667 6554', bic:	'KRED BE BB' },
  { type: 40014,	name: 'Scarabee',	adresStraat: 'Hingenesteenweg 26',	adresGemeente: '2880 Bornem',	btwNr: 'BE 0400 764 309', rpr :	'Antwerpen, afd. Mechelen',	iban: 'BE79 7370 0171 5933', bic:	'KRED BE BB' },
  { type: 40015,	name: 'Argus',	adresStraat: 'Lindenstraat 8',	adresGemeente: '9300 Aalst',	btwNr: 'BE 0475 786 285', rpr :	'Gent, afdeling Dendermonde',	iban: 'BE67 4421 0373 7587', bic:	'KRED BE BB' },
  { type: 40016,	name: 'Alex',	adresStraat: 'Noorderlaan 168',	adresGemeente: '2000 Antwerpen',	btwNr: 'BE 0449603 413', rpr :	'Antwerpen',	iban: 'BE23 0964 4375 2991', bic:	'GKCC BE BB' },
  { type: 40017,	name: 'Favorite',	adresStraat: 'Vlaslaan 17',	adresGemeente: '8500 Kortrijk',	btwNr: 'BE 0844 782 205', rpr :	'Gent, afd. Kortrijk',	iban: 'BE79 0013 1427 5733', bic:	'GEBA BE BB' },
  { type: 40018,	name: 'Oosterpoort',	adresStraat: 'Waterloosteenweg 812',	adresGemeente: '1180 Brussel',	btwNr: 'BE 0432 712 644', rpr :	'Brussel',	iban: 'BE71 3100 0230 6969', bic:	'BBRU BE BB' },
  { type: 40019,	name: 'Victorinox',	adresStraat: 'Smedenstraat 12',	adresGemeente: '8000 Brugge',	btwNr: 'BE 0405 107 731', rpr :	'Gent, afd. Brugge',	iban: 'BE52 0000 5267 1909', bic:	'BPOT E B1' },
  { type: 40020,	name: 'Westfield',	adresStraat: 'Kastanjelaan 14',	adresGemeente: '3140 Keerbergen',	btwNr: 'BE 0434 283 351', rpr :	'Leuven',	iban: 'BE40 2100 0178 5963', bic:	'GEBA BE BB' }
]
