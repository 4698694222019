import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Leverancier } from '../../models/Leverancier';
import { User } from '../../models/user';
import { map } from 'rxjs/operators';

@Injectable()
export class LeverancierService {

  constructor(private http: HttpClient) {

  }

  getAllLeveranciers(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/leveranciers/' + currentUser._id, {headers: headers})
      .pipe(map(res => res));
  }

  getLeverancier(leverancierId: string){
    return this.http.get('/api/leverancier/' + leverancierId)
    .pipe(map(res => res));
  }

  addLeverancier(leverancier: Leverancier){
    let URI = '/api/leverancier/';
    let headers = new HttpHeaders;
    let body = {
      type: leverancier.type,
      name: leverancier.name,
      btwNr: leverancier.btwNr,
      contactpersoon: leverancier.contactpersoon,
      email: leverancier.email,
      leverancierSubscr: leverancier.leverancierSubscr,
      land: leverancier.land,
      telnr: leverancier.telnr,
      adresStraat: leverancier.adresStraat,
      adresGemeente: leverancier.adresGemeente,
      postcode: leverancier.postcode,
      iban: leverancier.iban,
      bic: leverancier.bic,
      rpr: leverancier.rpr
    };
    headers.append('Content-Type','application/json');
    return this.http.post(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

  updateLeverancier(leverancier: Leverancier){
    let URI = `/api/leverancier/${leverancier._id}`
    let headers = new HttpHeaders;
    let body = {
      type: leverancier.type,
      name: leverancier.name,
      btwNr: leverancier.btwNr,
      contactpersoon: leverancier.contactpersoon,
      adresStraat: leverancier.adresStraat,
      adresGemeente: leverancier.adresGemeente,
      postcode: leverancier.postcode,
      land: leverancier.land,
      email: leverancier.email,
      telnr: leverancier.telnr,
      leverancierSubscr: leverancier.leverancierSubscr,
      iban: leverancier.iban,
      bic: leverancier.bic,
      rpr: leverancier.rpr
    }
    headers.append('Content-Type', 'application/json');
    return this.http.put(URI, body, {headers})
    .pipe(map(res => res));
  }

  removeLeverancier(leverancierId: string){
    let URI = `/api/leverancier/${leverancierId}`;
    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.delete(URI, {headers})
    .pipe(map(res => res));
  }
}
