import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from '@angular/forms';
import {  Observable } from 'rxjs';

@Directive({
  selector: '[appNumber]',
  providers: [{provide: NG_VALIDATORS, useExisting: NumberDirective, multi: true}]
})
export class NumberDirective implements Validator {

  constructor() {

  }

  validate(control: AbstractControl): ValidationErrors | null {
    if(control.value && control.value.length) {
      let regex = /^[0-9]+(?:[.,][0-9]{1,2})?$/gm;
      if(!regex.test(control.value)) {
        return {number: true};
      } else {
        return null;
      }
    }
  }
}
