import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import {Verkoop} from "../../../models/Verkoop";

@Injectable()
export class VerkoopService {

  verkopen = new BehaviorSubject([]);
  refreshVerkopen$ = new Subject();
  verkopenOriginal;

  constructor(private http: HttpClient) {

   }


  getAllVerkopen(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/verkopen/' + currentUser._id, {headers:headers})
      .pipe(map(res => res));
  }

  getStudentenVerkopen(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    return this.http.get('/api/verkopen/studenten/' + currentUser.leerkrachtCode, {headers: headers})
    .pipe(map(res => res));
  }

  getKlantenVerkopen(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    return this.http.get('/api/verkopen/klanten/' + currentUser.boekhouderCode, {headers: headers})
    .pipe(map(res => res));
  }

  addVerkoop(verkoop: Verkoop){
    let URI = '/api/verkoop/';
    let headers = new HttpHeaders;
    let body = {
      datum: verkoop.datum,
      klant: verkoop.klant,
      bedragExclBTW: verkoop.bedragExclBTW,
      BTW: verkoop.BTW,
      bedragInclBTW: verkoop.bedragInclBTW,
      omschrijving: verkoop.omschrijving,
      bijlage: verkoop.bijlage,
      status: verkoop.status,
      factuur: verkoop.factuur,
      intracommunautair: verkoop.intracommunautair,
      kortingVoorContant: verkoop.kortingVoorContant,
      terugstuurbareVerpakking: verkoop.terugstuurbareVerpakking,
      icType: verkoop.icType
    };
    headers.append('Content-Type','application/json');
    return this.http.post(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

  updateVerkoop(verkoop: Verkoop){
    let URI = `/api/verkoop/${verkoop._id}`
    let headers = new HttpHeaders;
    let body = {
      datum: verkoop.datum,
      klant: verkoop.klant,
      bedragExclBTW: verkoop.bedragExclBTW,
      BTW: verkoop.BTW,
      bedragInclBTW: verkoop.bedragInclBTW,
      omschrijving: verkoop.omschrijving,
      bijlage: verkoop.bijlage,
      status: verkoop.status,
      factuur: verkoop.factuur,
      intracommunautair: verkoop.intracommunautair,
      kortingVoorContant: verkoop.kortingVoorContant,
      terugstuurbareVerpakking: verkoop.terugstuurbareVerpakking,
      icType: verkoop.icType
    };
    headers.append('Content-Type', 'application/json');
    return this.http.put(URI, body, {headers})
    .pipe(map(res => res));
  }

  getVerkoop(verkoopId: string){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/verkoop/' + verkoopId, {headers: headers})
    .pipe(map(res => res));
  }



  removeVerkoop(verkoopId: string){
    let URI = `/api/verkoop/${verkoopId}`;
    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.delete(URI, {headers})
    .pipe(map(res => res));
  }

}
